import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"

class BG1ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BG1"
          description="View route information and buy your ticket for the BG1 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BG1 service</h1>
              <p>Park Avenue - Bradford Grammar School</p>
              <p>Bradford Grammar School - Park Avenue</p>
              <p>For information on fares and passes please contact the school via email @ <a href="mailto:transport@bradfordgrammar.com">transport@bradfordgrammar.com</a></p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <iframe
                  title="BG1 route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1GEqnIZWQGR4alTnu4aKjKdN2GjOz-Zc&hl=en&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>AM Towards</strong> Bradford Grammar School
                </p>
                <p>
                  From Park Avenue, Princess Avenue, Street Lane, Park Lane, A6120 Ring Road, Park Side, New Road Side, A6120 Ring Road, Rodley Lane, Town Gate, Woodhall Road, Woodhall Lane, A647 Bradford Road, Leeds Road, Dick Lane, Gipsy Lane, Leeds Old Road, Killinghall Road, Northcote Road, Valley View Grove, Lister Lane, Bolton Road, Queens Road, Manningham Lane, Keighley Road, Frizinghall Road, School Bus Bays.
                </p>

                <p>
                  <strong>PM From</strong> Bradford Grammar School
                </p>
                <p>
                  School Bus Bays, Frizinghall Road, Keighley Road, Manningham Lane, Queens Road, Bolton Road, Lister Lane, Valley View Grove, Northcote Road, Killinghall Road, Leeds Old Road, Leeds Road, Bradford Road, Woodhall Lane, Woodhall Road, Town Gate, Rodley Lane, A6120 Ring Road, New Road Side, Park Side, Ring Road, Park Lane, Street Lane, Princess Avenue, Park Avenue.
                </p>

                <h4>Bus stop information</h4>
                <ul>
                  <li>Most stops listed are located at designated public bus stops however, a small number of stops (* marked with an asterisk), are not located at public bus stops; where this is the case, the exact location is noted.</li>
                  <li>Pupils are not permitted to board the bus at any other location than the stops listed. Pupils must arrive at their AM stop at least 5 minutes ahead of the times shown; the bus will depart on time and will not wait.</li>
                  <li>AM and PM times may vary due to unforeseen circumstances e.g. traffic, roadworks etc. Should a bus be delayed by more than 10 minutes, the school will make every effort to contact parents however, it is recommended that parents contact the bus company directly for updates</li>
                </ul>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable" style={{ fontSize: '0.9em' }}>
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Oakwood</td>
                      <td>Park Avenue * <span className="timetable-hint">End of Park Avenue; opposite the Clock Tower</span></td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>Roundhay</td>
                      <td>Princess Avenue <span className="timetable-hint">Opposite main gates to Roundhay park</span></td>
                      <td>0717</td>
                    </tr>
                    <tr>
                      <td>Moortown</td>
                      <td>A6120 Ring Road/Queenshill Crescent/Lingfield Road</td>
                      <td>0725</td>
                    </tr>
                    <tr>
                      <td>Weetwood</td>
                      <td>A6120 Ring Road/Weetwood Lane</td>
                      <td>0729</td>
                    </tr>
                    <tr>
                      <td>Weetwood</td>
                      <td>A6120 Ring Road/Lawnswood School</td>
                      <td>0734</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>A6120 Ring Road/Broadway <span className="timetable-hint">Bus lay-by</span></td>
                      <td>0739</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>A65 New Road Side/Manor Fold * <span className="timetable-hint">Pick up at public bus stop, drop off directly outside The Fleece Pub main entrance on A65 New Road Side</span></td>
                      <td>0744</td>
                    </tr>
                    <tr>
                      <td>Rodley</td>
                      <td>A657 Rodley Lane/Hawthorn Grove</td>
                      <td>0753</td>
                    </tr>
                    <tr>
                      <td>Calverley</td>
                      <td>A657 Towngate/Woodhall Road</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Calverley</td>
                      <td>Calverley Golf Club</td>
                      <td>0757</td>
                    </tr>
                    <tr>
                      <td>Pudsey</td>
                      <td>Rockwood Road/Woodhall Lane</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Pudsey</td>
                      <td>A647 Bradford Road/Moorland Grove <span className="timetable-hint">Outside Army Reserve Centre</span></td>
                      <td>0804</td>
                    </tr>
                    <tr>
                      <td>Bradford</td>
                      <td>B6381 Leeds Old Road/Roydstone Terrace</td>
                      <td>0810</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable" style={{ fontSize: '0.9em' }}>
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Bradford</td>
                      <td>B6381 Leeds Old Road/Roydstone Terrace</td>
                      <td>1630</td>
                    </tr>
                    <tr>
                      <td>Pudsey</td>
                      <td>A647 Bradford Road/Moorland Grove <span className="timetable-hint">Outside Army Reserve Centre</span></td>
                      <td>1636</td>
                    </tr>
                    <tr>
                      <td>Pudsey</td>
                      <td>Rockwood Road/Woodhall Lane</td>
                      <td>1640</td>
                    </tr>
                    <tr>
                      <td>Calverley</td>
                      <td>Calverley Golf Club</td>
                      <td>1642</td>
                    </tr>
                    <tr>
                      <td>Calverley</td>
                      <td>A657 Towngate/Woodhall Road</td>
                      <td>1645</td>
                    </tr>
                    <tr>
                      <td>Rodley</td>
                      <td>A657 Rodley Lane/Hawthorn Grove</td>
                      <td>1647</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>A65 New Road Side/Manor Fold * <span className="timetable-hint">Pick up at public bus stop, drop off directly outside The Fleece Pub main entrance on A65 New Road Side</span></td>
                      <td>1656</td>
                    </tr>
                    <tr>
                      <td>Horsforth</td>
                      <td>A6120 Ring Road/Broadway <span className="timetable-hint">Bus lay-by</span></td>
                      <td>1701</td>
                    </tr>
                    <tr>
                      <td>Weetwood</td>
                      <td>A6120 Ring Road/Lawnswood School</td>
                      <td>1706</td>
                    </tr>
                    <tr>
                      <td>Weetwood</td>
                      <td>A6120 Ring Road/Weetwood Lane</td>
                      <td>1711</td>
                    </tr>
                    <tr>
                      <td>Moortown</td>
                      <td>A6120 Ring Road/Queenshill Crescent/Lingfield Road</td>
                      <td>1715</td>
                    </tr>
                    <tr>
                      <td>Roundhay</td>
                      <td>Princess Avenue <span className="timetable-hint">Opposite main gates to Roundhay park</span></td>
                      <td>1723</td>
                    </tr>
                    <tr>
                      <td>Oakwood</td>
                      <td>Park Avenue * <span className="timetable-hint">End of Park Avenue; opposite the Clock Tower</span></td>
                      <td>1725</td>
                    </tr>
                  </tbody>
                </table>


              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default BG1ServicePage
